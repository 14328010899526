import React from 'react';
import { graphql } from 'gatsby';
import { Flex } from 'rebass';

import { MainContainer, PageContainer } from '../../common';
import Intro from '../../components/Intro/Intro';
import SideBar from '../../components/SideBar/SideBar';
import Cta from '../../components/Cta/Cta';

import { styleVars } from '../../lib/helpers/style';

import AuthoredAccordion from '../../components/Authored/Accordion/Accordion';
import AuthoredHeading from '../../components/Authored/Heading/Heading';
import AuthoredImage from '../../components/Authored/Image/Image';
import AuthoredVideo from '../../components/Authored/Video/Video';
import AuthoredLink from '../../components/Authored/Link/Link';
import AuthoredText from '../../components/Authored/Text/Text';
import AuthoredBlockquote from '../../components/Authored/Blockquote/Blockquote';
import AuthoredFactoid from '../../components/Authored/Factoid/Factoid';
import AuthoredDocument from '../../components/Authored/Document/Document';
import HorizontalRuler from '../../components/HorizontalRuler/HorizontalRuler';

export const query = graphql`
  fragment mainLandingContent on Drupal_NodeMainLanding {
    fieldIntro {
      entity {
        ...introBlurb
      }
    }
    fieldCtas {
      entity {
        ... on Drupal_ParagraphCta {
          fieldTitle
          fieldLink {
            title
            url {
              path
            }
          }
        }
      }
    }
    fieldAuthoredSections {
      entity {
        entityId
        entityBundle
        ...authoredAccordion
        ...authoredHeading
        ...authoredImage
        ...authoredLink
        ...authoredText
        ...authoredVideo
        ...authoredBlockquote
        ...authoredFactoid
        ...authoredDocument
      }
    }
    fieldTags {
      entity {
        ...topicTags
      }
    }
  }
  query($nid: String!, $pagePath: String!) {
    drupal {
      nodeById(id: $nid) {
        ...mainLandingContent
      }
    }
    sitePage(path: { eq: $pagePath }) {
      children {
        ...childFiles
      }
    }
  }
`;

const MainLanding = ({ data, pageContext: { overviewLinks, pagePath } }) => {
  const node = data.drupal.nodeById;
  const files = data.sitePage.children;

  const { fieldIntro, fieldCtas, fieldAuthoredSections, fieldTags } = node;

  const componentMapping = {
    // drupal machine name : component name
    accordion: AuthoredAccordion,
    heading: AuthoredHeading,
    image: AuthoredImage,
    link: AuthoredLink,
    text: AuthoredText,
    video: AuthoredVideo,
    blockquote: AuthoredBlockquote,
    factoid: AuthoredFactoid,
    document: AuthoredDocument,
    horizontal_rule: HorizontalRuler,
  };

  return (
    <>
      <MainContainer row className={`pt-${styleVars.mobileBreakpoint}-9 pb-9`}>
        <SideBar overviewLinks={overviewLinks} fieldTags={fieldTags} />

        <PageContainer className={`pt-8 pt-${styleVars.mobileBreakpoint}-0`}>
          {fieldIntro && <Intro entity={fieldIntro} />}
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{ marginTop: '64px', marginBottom: '48px', width: '100%' }}
          >
            {fieldCtas.map(({ entity: { fieldTitle, fieldLink } }) => (
              <Cta key={fieldTitle} fieldTitle={fieldTitle} fieldLink={fieldLink} useButtonLinks={true} />
            ))}
          </Flex>
          {fieldAuthoredSections.map(({ entity }) => {
            const { entityBundle, entityId } = entity;
            const Component = componentMapping[entityBundle];

            return Component ? <Component key={entityId} entity={entity} files={files} /> : null;
          })}
        </PageContainer>
      </MainContainer>
    </>
  );
};

export default MainLanding;
