import React from 'react';
import { Box, Card } from 'rebass';

import { Text, Link, Button } from '../../common';

const Cta = ({ fieldTitle, fieldLink, useButtonLinks = false }) => {
  return (
    <Card className="cta position-relative mb-4" width={[1, 0.48]}>
      <div className="cta-shadow" />
      <Card className="cta-card d-flex flex-column position-absolute">
        <Box
          sx={{
            bg: 'darkestBlue',
            height: '8px',
            width: '100%',
            borderRadius: '4px 4px 0 0',
          }}
          style={{ backgroundColor: '#01073c' }}
        />
        <div className="p-4 d-flex flex-column flex-grow-1 justify-content-between align-items-center text-center">
          <Text as="p" fontSize={[5]} styledAs="h2" fontWeight="heading">
            {fieldTitle}
          </Text>
          {useButtonLinks ? (
            <Button
              as={Link}
              styledAs="button"
              href={fieldLink.url.path}
              style={{ color: 'white', textDecoration: 'none', backgroundColor: '#304ffe' }}
              variant="primary"
              sx={{ justifyContent: 'center', width: '100%' }}
            >
              {fieldLink.title}
            </Button>
          ) : (
            <Link href={fieldLink.url.path}>{fieldLink.title || fieldLink.url.path}</Link>
          )}
        </div>
      </Card>
    </Card>
  );
};

export default Cta;
